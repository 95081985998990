<!--subscribe start-->
<section class="resume subscribe" id="contact">
  <div class="container">
      <div class="row">
          <div class="col-lg-8 offset-lg-2">
              <div class="title title2">
                  <h3 class="subscribe-head">Need someone experienced for your project?</h3>
                  <p class="subscribe-sub-head font-primary">Please feel free to send me a message, and I will get back to you shortly.</p>
              </div>
          </div>
          <div class="col-xl-6 offset-xl-3 col-lg-8 offset-lg-2">
              <div class="subscribe">
                  <div class="center-content">
                      <form class="form-group" [formGroup]="form" id="contactForm" method="post" (ngSubmit)="onSubmit()">
                          <div class="flex">
                            <input type="text" class="form-control" placeholder="Name *" [formControl]="name" required>
                            <input type="email" class="form-control" placeholder="Email Address *" [formControl]="email" required>
                            <textarea class="form-control" rows="5" cols="60" maxlength="256" placeholder="Enter message here *" [formControl]="message" required></textarea>
                            <button [disabled]="isLoading" type="submit" class="btn btn-default text-white primary-btn">Send</button>
                            <input [formControl]="honeypot" class="hidden" type="text" />
                            <div [ngClass]="!submitted? 'hidden' : 'visible'" class="center-text">
                                <span>{{responseMessage}}</span>
                            </div>
                          </div>
                        </form>
                  </div>
              </div>
          </div>
      </div>
  </div>
</section>
<!--subscribe end-->
