<!--megamenu section Start-->
<header class="dark loding-header custom-scroll nav-lg">
    <div class="container">
        <div class="row">
            <div class="col">
                <nav>
                    <a href="#" class="d-inline-block m-r-auto"><img src="assets/images/logo/5.png" alt="" class="img-fluid"></a>
                    <app-menu></app-menu>
                </nav>
            </div>
        </div>
    </div>
</header>
<!--megamenu section end-->