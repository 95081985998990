<a  class="overlay-sidebar-header" [class.overlay-sidebar-header-open]="openSide" (click)="closeOverlay()"></a>
<div class="responsive-btn">
    <a (click)="toggleSidebar()">
        <i class="fa fa-bars text-white" aria-hidden="true"></i>
    </a>
</div>
<div class="navbar m-l-auto" id="togglebtn" [class.openSidebar]="openSide" >
    <div class="responsive-btn">
        <a (click)="toggleSidebar()">
            <h5>back</h5>
        </a>
    </div>
    <ul class="main-menu">
        <li>
            <a (click)="onClick('top')" class="menuItem">Home</a>
            <a (click)="onClick('experience')" class="menuItem">Experience</a>
            <a (click)="onClick('education')" class="menuItem">Education</a>
            <a (click)="onClick('skills')" class="menuItem">Skills</a>
            <a (click)="onClick('contact')" class="menuItem">Contact</a>
        </li>
        </ul>  
</div>
  
