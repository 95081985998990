<div class="row">
    <div class="col-12">
        <div class="filter-container isotopeFilters">
            <ul class="list-inline filter">
                <li [ngClass]="{'active': customizer == 'all' }"><a (click)="openGallery('all')">All </a></li>
                <li [ngClass]="{'active': customizer == 'photos' }"><a (click)="openGallery('photos')">Photos</a>
                </li>
                <li [ngClass]="{'active': customizer == 'videos' }"><a (click)="openGallery('videos')">Videos</a></li>
            </ul>
        </div>
    </div>
</div>
<div class="isotopeContainer row">
    <ng-container *ngIf="customizer=='all'">
        <figure class="my-app-custom-image-with-desc p-width" *ngFor="let img of allImages">
            <div class="isotopeSelector shoes fashion ">
                <div class="overlay">
                    <div class="border-portfolio">
                        <div class="overlay-background">
                            <i class="fa fa-plus" aria-hidden="true" (click)="openImageModalRowDescription(img)"></i>
                        </div>
                        <img [src]="img.modal.img" />
                    </div>
                </div>
            </div>
        </figure>
    </ng-container>
    <ng-container *ngIf="customizer=='photos'">
        <app-photos class="w-100"></app-photos>
    </ng-container>
    <ng-container *ngIf="customizer=='videos'">
        <app-videos class="w-100"></app-videos>
    </ng-container>
    <ks-modal-gallery [id]="1" [modalImages]="allImages" [plainGalleryConfig]="customPlainGalleryRowDescConfig"
        [buttonsConfig]="buttonsConfigCustom">
    </ks-modal-gallery>
</div>
<!-- pagination Start -->
<!-- <div class="pagination_sec">
    <div class="content_detail__pagination cdp">
        <ul>
            <li><a href="javascript:void(0)" class="prev"><i class="fa fa-angle-double-left" aria-hidden="true"></i></a>
            </li>
            <li><a href="javascript:void(0)" class="active cdp_i">1</a></li>
            <li><a href="javascript:void(0)" class="cdp_i">2</a></li>
            <li><a href="javascript:void(0)" class="cdp_i">3</a></li>
            <li><a href="javascript:void(0)" class="next"><i class="fa fa-angle-double-right"
                        aria-hidden="true"></i></a></li>
        </ul>
    </div>
</div> -->
<!-- pagination End -->