<!--breadcrumb section start-->
<section class="portfolio-metro bg p-b-0" [ngStyle]="{'background-image': 'url(assets/images/portfolio/portfolio-new/font.png)'}">
        <div class="container-fluid">
            <div class="container">
                <div class="row">
                    <div class="col-md-9 col-sm-9 text-center ">
                        <div class="portfolio_section">
                            <div>
                                <h1 class="breadcrumb-text"> great <span class="bold-text color-animated">portfolio metro</span></h1>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-2 col-sm-3 text-center">
                        <img alt="" class="img-fluid man" src="assets/images/portfolio/portfolio-new/man.png">
                    </div>
                </div>
            </div>
        </div>
    </section>
    <!--breadcrumb section end-->