<!--copyright start-->
<footer class="resume copyright copyright-bg">
  <div class="container">
      <div class="row">
          <div class="col-12">
              <div class="text-center">
                  <div class="social-link link-horizontal">
                      <ul class="justify-content-center">
                          <li><a href="https://www.facebook.com/prad5" target="_blank" class="copyright-text"><i class="fa fa-facebook" aria-hidden="true"></i></a></li>
                          <li><a href="https://www.twitter.com/paulradaker" target="_blank" class="copyright-text"><i class="fa fa-twitter" aria-hidden="true"></i></a></li>
                          <li><a href="https://www.instagram.com/paulradaker" target="_blank" class="copyright-text "><i class="fa fa-instagram" aria-hidden="true"></i></a></li>
                          <li><a href="https://www.youtube.com/paulradaker" target="_blank" class="copyright-text "><i class="fa fa-youtube" aria-hidden="true"></i></a></li>
                          <li><a href="https://www.linkedin.com/in/paulradaker" target="_blank" class="copyright-text"><i class="fa fa-linkedin" aria-hidden="true"></i></a></li>
                      </ul>
                  </div>
              </div>
          </div>
          <div class="col-12">
              <div class="m-l-auto center-para">
                  <h6 class="copyright-text text-center">&copy; 2024 Paul Radaker. All Rights Reserved.<br /><span style="font-size:9pt !important;">Loading animation provided by <a href="https://www.loading.io" target="_blank" style="font-size:9pt !important;">loading.io</a></span></h6>
              </div>
          </div>
      </div>
  </div>
</footer>
<!--copyright end-->
<app-tap-to-top></app-tap-to-top>