<div class="loader-wrapper" [class.loderhide]="!show">
<div class="loadingio-spinner-double-ring-tljllnnweca"><div class="ldio-1s5071la1v9">
  <div></div>
  <div></div>
  <div><div></div></div>
  <div><div></div></div>
  </div></div>
</div>

<style type="css/html">
    @keyframes ldio-1s5071la1v9 {
    0% { transform: rotate(0) }
    100% { transform: rotate(360deg) }
  }
  .ldio-1s5071la1v9 div { box-sizing: border-box!important }
  .ldio-1s5071la1v9 > div {
    position: absolute;
    width: 144px;
    height: 144px;
    top: 28px;
    left: 28px;
    border-radius: 50%;
    border: 16px solid #000;
    border-color: #09456a transparent #09456a transparent;
    animation: ldio-1s5071la1v9 1s linear infinite;
  }
  
  .ldio-1s5071la1v9 > div:nth-child(2), .ldio-1s5071la1v9 > div:nth-child(4) {
    width: 108px;
    height: 108px;
    top: 46px;
    left: 46px;
    animation: ldio-1s5071la1v9 1s linear infinite reverse;
  }
  .ldio-1s5071la1v9 > div:nth-child(2) {
    border-color: transparent #66a6ff transparent #66a6ff
  }
  .ldio-1s5071la1v9 > div:nth-child(3) { border-color: transparent }
  .ldio-1s5071la1v9 > div:nth-child(3) div {
    position: absolute;
    width: 100%;
    height: 100%;
    transform: rotate(45deg);
  }
  .ldio-1s5071la1v9 > div:nth-child(3) div:before, .ldio-1s5071la1v9 > div:nth-child(3) div:after { 
    content: "";
    display: block;
    position: absolute;
    width: 16px;
    height: 16px;
    top: -16px;
    left: 48px;
    background: #09456a;
    border-radius: 50%;
    box-shadow: 0 128px 0 0 #09456a;
  }
  .ldio-1s5071la1v9 > div:nth-child(3) div:after {
    left: -16px;
    top: 48px;
    box-shadow: 128px 0 0 0 #09456a;
  }
  
  .ldio-1s5071la1v9 > div:nth-child(4) { border-color: transparent; }
  .ldio-1s5071la1v9 > div:nth-child(4) div {
    position: absolute;
    width: 100%;
    height: 100%;
    transform: rotate(45deg);
  }
  .ldio-1s5071la1v9 > div:nth-child(4) div:before, .ldio-1s5071la1v9 > div:nth-child(4) div:after {
    content: "";
    display: block;
    position: absolute;
    width: 16px;
    height: 16px;
    top: -16px;
    left: 30px;
    background: #66a6ff;
    border-radius: 50%;
    box-shadow: 0 92px 0 0 #66a6ff;
  }
  .ldio-1s5071la1v9 > div:nth-child(4) div:after {
    left: -16px;
    top: 30px;
    box-shadow: 92px 0 0 0 #66a6ff;
  }
  .loadingio-spinner-double-ring-tljllnnweca {
    width: 200px;
    height: 200px;
    display: inline-block;
    overflow: hidden;
    background: #ebf3ff;
  }
  .ldio-1s5071la1v9 {
    width: 100%;
    height: 100%;
    position: relative;
    transform: translateZ(0) scale(1);
    backface-visibility: hidden;
    transform-origin: 0 0; /* see note above */
  }
  .ldio-1s5071la1v9 div { box-sizing: content-box; }
  /* generated by https://loading.io/ */
</style>