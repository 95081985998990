import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-resume-services',
  templateUrl: './resume-services.component.html',
  styleUrls: ['./resume-services.component.scss']
})
export class ResumeServicesComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

  service = [
    {
      img:"assets/images/resume/service-experience/1.png",
      type:'Photography',
      description:'<ul><li>Full-frame and APS-C cameras</li><li>ISO, shutter speed, and aperture</li><li>Photoshop and Lightroom</li><li>Subject framing</li><li>Online portfolio</li></ul>'
    },
    {
      img:"assets/images/resume/service-experience/2.png",
      type:'Video Production',
      description:'<ul><li>RED, DJI, Blackmagic Design, and Sony cinema cameras</li><li>Video lighting equipment</li><li>Shotgun and lavalier microphones</li><li>Video frame composition</li><li>Final Cut Pro</li><li>Davinci Resolve</li></ul>'
    },
    {
      img:"assets/images/resume/service-experience/3.png",
      type:'OS Familiarity',
      description:'<ul><li>Windows 10/11</li><li>macOS</li><li>iOS/iPadOS</li><li>Linux</li><li>Android</li></ul>'
    },
    {
      img:"assets/images/resume/service-experience/5.png",
      type:'Web Development',
      description:'<ul><li>Angular</li><li>Google Firebase</li><li>Git</li><li>Visual Studio Code</li><li>HTML5/CSS3</li><li>Javascript/Typescript</li><li>FTP/SFTP/SSH</li></ul>'
    },
   ]


}
