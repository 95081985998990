import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-resume-schedule',
  templateUrl: './resume-schedule.component.html',
  styleUrls: ['./resume-schedule.component.scss']
})
export class ResumeScheduleComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }
  
schedule = [
  {
    name:'Radaker Media',
    location:'Noblesville, IN',
    title:'Photographer/Videographer',
    description:'Filming weddings, advertisements, award ceremonies, and more. Editing videos for other businesses using Final Cut Pro or Davinci Resolve. Working directly with the customer.',
    toTime: 'January 2018',
    endTime: 'present'
  },
  {
    name:'Bob Thomas Dealerships',
    location:'Indianapolis, IN',
    title:'Vehicle Photographer',
    description:'Taking photos of several different angles, interior and exterior, of used and new cars as they come in. Editing photos in Adobe Lightroom. Uploading the photos in vAuto and backing them up to the cloud.',
    toTime: 'April 2019',
    endTime: 'present'
  },
  {
    name:'RadiculousTech',
    location:'Noblesville, IN',
    title:'IT Specialist',
    description:'Helped clients with technology problems. Achieved 100% satisfaction rating with customers. Gained experience with electronic money transactions.',
    toTime: 'September 2012',
    endTime: 'August 2017'
  }
]

schedule2 = [
  {
    name:'Information Systems Coursework',
    school:'Anderson University',
    location:'Anderson, IN',
    description:'Computer Science I, Computer Science II, Visual Programming, Business Computer Applications, Theory/Design of Programming Languages',
    toTime: '2012',
    endTime: '2016'
  },
  {
    name:'Core 40 Diploma',
    school:'Hamilton Southeastern High School',
    location:'Fishers, IN',
    description:'<em>Computer Science courses:</em> Web Design I, Web Design II, Computer Programming I, Computer Programming II',
    toTime: '2009',
    endTime: '2012'
  }
]
}
