<!--schedule start-->
<section class="resume schedule">
  <div class="container">
      <div class="title title2">
          <div class="row">
              <div class=" offset-md-2 col-md-8">
                <div class="">
                    <h2 class="title-text">Experience</h2>
                </div>
              </div>
          </div>
      </div>
      <div class="row">
          <div class="col-12">
              <div class="cal-time">
                  <div class="time">
                      <h5 class="text-capitalize"><span class="fa fa-briefcase"></span>&nbsp;Work</h5>
                  </div>
              </div>
              <div class="event-container">
                  <div>
                      <div class=" cal-time events" *ngFor="let data of schedule">
                          <div class="row">
                              <div class="col-md-10">
                                  <div class="schedule-event">
                                      <h4 class="names">{{data.name}}</h4>
                                      <p class="schedule-location"><span class="fa fa-location-arrow"></span>&nbsp;{{data.location}}</p>
                                      <p class="schedule-title"><span class="fa fa-briefcase"></span>&nbsp;{{data.title}}</p>
                                      <p class="schedule-description">{{data.description}}</p>
                                  </div>
                              </div>
                              <div class="col-md-2">
                                  <div class="timing">
                                      <h5 class="event-time">{{data.toTime}}</h5>
                                      <div class="border-container">
                                          <div class="border-dashed"></div>
                                      </div>
                                      <h5 class="event-time">{{data.endTime}}</h5>
                                  </div>
                              </div>
                          </div>
                      </div>
                  </div>
              </div>
          </div>
      </div>
    <a id="education"></a><div class="row" style="margin-top:62px !important;">
        <div class="col-12">
            <div class="cal-time">
                <div class="time">
                    <h5 class="text-capitalize"><span class="fa fa-graduation-cap"></span>&nbsp;Education</h5>
                </div>
            </div>
            <div class="event-container">
                <div>
                    <div class=" cal-time events" *ngFor="let data of schedule2">
                        <div class="row">
                            <div class="col-md-10">
                                <div class="schedule-event">
                                    <h4 class="names">{{data.name}}</h4>
                                    <p class="schedule-title"><span class="fa fa-graduation-cap"></span>&nbsp;{{data.school}}</p>
                                    <p class="schedule-location"><span class="fa fa-location-arrow"></span>&nbsp;{{data.location}}</p>
                                    <p class="schedule-description" [innerHTML]="data.description"></p>
                                </div>
                            </div>
                            <div class="col-md-2">
                                <div class="timing">
                                    <h5 class="event-time">{{data.toTime}}</h5>
                                    <div class="border-container">
                                        <div class="border-dashed"></div>
                                    </div>
                                    <h5 class="event-time">{{data.endTime}}</h5>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
</section>
<!--schedule end-->
