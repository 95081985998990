<!--header start-->
<section class="resume header" id="header">
  <div class="header7-content">
      <div class="resume bg bg-pink" style="background-size:cover; background: rgb(137,247,254); background: radial-gradient(farthest-corner at 20% 20%, rgba(137,247,254,1) 0%, rgba(137,247,254,1) 30%, rgba(102,166,255,1) 100%);">
          <div class="container">
              <div class="row">
                  <div class="col-lg-5 col-md-6">
                      <img src="assets/images/resume/h7.png" alt="" class="hero-graphic img-fluid set-abs bottom-0 right-0">
                  </div>
                  <div class="col-lg-6 col-md-6 offset-lg-1">
                      <div class="center-text">
                          <div>
                              <div class="header-text">
                                  <div class="mobile-hero"><img src="assets/images/resume/paul.png" alt="" class="mobile-hero-graphic"></div>
                                  <h1>Hi, I’m<br><span class="bold-text">Paul Radaker</span></h1>
                              </div>
                              <div class="header-sub-text">
                                  <h4 style="color:#395363 !important;" class="font-secondary">Videographer, Content Creator, Web Developer</h4>
                              </div>
                              <!-- <div class="header-sub-text">
                                  <p class="font-secondary font-large">Lorem Ipsum is simply dummy text of the printing andtypesetting
                                      industry. Lorem Ipsum has been the industry's standard dummy textever since the 1500s, when an unknown.</p>
                              </div> -->
                              <div class="link-horizontal">
                                  <ul>
                                      <li><a class="btn btn-default primary-btn back-white" (click)="onClick('experience')">Learn More</a></li>
                                      <li><a href="https://drive.google.com/file/d/12INSZ2AzKpU4pOEF4zOKA02TO3QA-1hk/view" target="_blank" class="btn btn-default primary-btn back-white">Download CV</a></li>
                                  </ul>
                              </div>
                          </div>
                      </div>
                  </div>
              </div>
          </div>
      </div>
  </div>
</section>
<!--header end-->