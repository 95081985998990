<!-- Nav Start-->
<header class="resume loding-header nav-abs custom-scroll">
  <div class="container">
      <div class="row">
          <div class="col">
                <nav>
                        <a href="#" class="logo d-inline-block m-r-auto"><img src="assets/images/logo/5.png" alt="" class="img-fluid"></a>
                        <app-menu></app-menu>
              </nav>
          </div>
      </div>
  </div>
</header>
<!-- Nav end-->
