<!-- copyright start -->
<div class="agency copyright inner-page">
    <div class="container">
        <div class="row">
            <div class="col-sm-6">
                <div class="link-horizontal">
                    <ul>
                        <li>
                            <a href="javascript(0)" class="copyright-text">Privacy Policy</a>
                        </li>
                        <li>
                            <a href="javascript(0)" class="copyright-text">Terms &amp; Conditions</a>
                        </li>
                    </ul>
                </div>
            </div>
            <div class="col-sm-6">
                <div>
                    <h6 class="copyright-text text-white text-right">Copyright ©2018 unice by <i
                            class="fa fa-heart"
                            aria-hidden="true"></i>
                        pixelstrap</h6>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- copyright end -->
